import React, { useContext } from 'react';
import {
  PDivider,
  PIcon,
  PText,
} from '@porsche-design-system/components-react';
import style from './membership.module.scss';
import { NorthAmericaOnly } from '../../../context/plan/discriminators/region/NorthAmericaOnly';
import { UnitedStatesOnly } from '../../../context/plan/discriminators/region/UnitedStatesOnly';
import { CanadaOnly } from '../../../context/plan/discriminators/region/CanadaOnly';
import { V2Only } from '../../../context/plan/discriminators/tariff/V2Only';
import { PremiumOnly } from '../../../context/plan/discriminators/tariff/PremiumOnly';
import { BasicOnly } from '../../../context/plan/discriminators/tariff/BasicOnly';
import { messages } from './membership.messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { featureFlagsContext } from 'components/context/featureFlags/FeatureFlagsContext';
import { observer } from 'mobx-react-lite';

/**
 * Displays advantages of an Electrify America/Canada membership
 * @constructor
 */

const premiumBasicInfos = [
  // {
  //   infoTitle: messages.inclusiveAc,
  //   contentTitle: '60 min flatrate',
  //   isFlatRate: true,
  //   hasSubtitle: true,
  //   id: 1,
  // },
  {
    infoTitle: messages.inclusiveDc,
    contentTitle: messages.inclusiveFlatrate,
    amountOfMinutes: 30,
    isFlatRate: true,
    hasSubtitle: true,
    id: 2,
  },
  {
    infoTitle: messages.inclusiveGrace,
    contentTitle: '10 min',
    id: 3,
  },
  {
    infoTitle: messages.inclusiveIdle,
    additionalInfo: messages.inclusiveIdleSubtitle,
    id: 4,
  },
  {
    infoTitle: messages.inclusiveChargePoint,
    contentTitle: messages.inclusiveChargePointSubtitle,
    subContent: messages.inclusiveChargePointSubContent,
    id: 5,
  },
];

export const Membership: React.FunctionComponent<{
  isNarBasicPremium?: boolean;
  isInvitationProcess?: boolean;
}> = observer(({ isNarBasicPremium, isInvitationProcess }) => {
  const intl = useIntl();
  const { featureFlags } = useContext(featureFlagsContext);

  const narPremiumInclusiveInfo = premiumBasicInfos.map((info, index) => {
    if (info.id === 5 && !featureFlags.chargePoint?.enabled) {
      return null;
    }

    let extraInformation = null;
    if (info.additionalInfo) {
      extraInformation = <FormattedMessage {...info.infoTitle} />;
    } else if (typeof info.contentTitle === 'string') {
      extraInformation = info.contentTitle;
    } else {
      extraInformation = (
        <FormattedMessage
          values={{
            amount: info.amountOfMinutes,
          }}
          {...info.contentTitle}
        />
      );
    }

    return (
      <div className={style.narItem} key={info.id}>
        <div className={style.narItemContentContainer}>
          <PText
            color={isInvitationProcess ? undefined : 'contrast-medium'}
            size="medium"
            weight={isInvitationProcess ? 'semi-bold' : undefined}
            className={style.narItemContentWithoutTitle}
          >
            <FormattedMessage {...info.infoTitle} />
          </PText>

          <div className={style.narItemContentWithTitle}>
            <PText weight="semi-bold" align="right" size="medium">
              {extraInformation}
            </PText>

            {info.hasSubtitle ? (
              <PText color="contrast-medium" size="x-small" align="right">
                <FormattedMessage {...messages.inclusiveSubtitle} />
                <span className={style.narStarContainer}>
                  <span className={style.narStar}>**</span>
                </span>
              </PText>
            ) : null}
          </div>
        </div>
        {info.subContent ? (
          <PText
            color="contrast-medium"
            size="x-small"
            className={style.subContent}
          >
            <FormattedMessage {...info.subContent} />
          </PText>
        ) : null}

        {(!isInvitationProcess || index < premiumBasicInfos.length - 1) && (
          <PDivider
            color={isInvitationProcess ? 'contrast-low' : 'contrast-medium'}
          />
        )}
      </div>
    );
  });

  const narPremiumInclusiveInfoContainer = (
    <div className={style.narContainer}>
      {narPremiumInclusiveInfo}

      {!isInvitationProcess && (
        <div className={style.taxesDisclaimer}>
          <PText color="contrast-medium">
            <span className={style.narStarContainer}>
              <span className={style.narStar}>*</span>
            </span>
            <FormattedMessage {...messages.inclusiveTaxDisclaimer} />
          </PText>

          <PText color="contrast-medium">
            <span className={style.narStarContainer}>
              <span className={style.narStar}>**</span>
            </span>
            <FormattedMessage {...messages.inclusivePricesDisclaimer} />
          </PText>

          {featureFlags.chargePoint?.enabled ? (
            <>
              <PText color="contrast-medium">
                <span className={style.narStarContainer}>
                  <span className={style.narStar}>***</span>
                </span>
                <FormattedMessage
                  {...messages.inclusiveChargePointDisclaimer}
                />
              </PText>

              <PText color="contrast-medium">
                <span className={style.narStarContainer}>
                  <span className={style.narStar}>****</span>
                </span>
                <FormattedMessage
                  {...messages.inclusiveChargePointDisclaimerDisclaimer}
                />
              </PText>
            </>
          ) : null}
        </div>
      )}
    </div>
  );

  return (
    <div data-testid={'membership'}>
      <NorthAmericaOnly>
        {isNarBasicPremium ? (
          narPremiumInclusiveInfoContainer
        ) : (
          <div>
            <V2Only>
              <div className={style.root}>
                <div>
                  <PIcon name={'plug'} aria={{ 'aria-label': 'Plug icon' }} />
                  <PText>
                    <FormattedMessage {...messages.plugAndCharge} />
                  </PText>
                </div>
                <div>
                  <PIcon
                    name={'purchase'}
                    aria={{ 'aria-label': 'Purchase icon' }}
                  />
                  <PText>
                    <PremiumOnly>
                      <UnitedStatesOnly>
                        {intl
                          .formatMessage(messages.premiumPricingUs)
                          .split('\\n')
                          .map((text, index) => (
                            <React.Fragment key={index}>
                              {text}
                              <br />
                            </React.Fragment>
                          ))}
                      </UnitedStatesOnly>
                      <CanadaOnly>
                        <FormattedMessage {...messages.premiumPricingCa} />
                      </CanadaOnly>
                    </PremiumOnly>
                    <BasicOnly>
                      <UnitedStatesOnly>
                        <FormattedMessage {...messages.basicPricingUs} />
                      </UnitedStatesOnly>
                      <CanadaOnly>
                        <FormattedMessage {...messages.basicPricingCa} />
                      </CanadaOnly>
                    </BasicOnly>
                  </PText>
                </div>
                <div>
                  <PremiumOnly>
                    <PIcon name={'star'} aria={{ 'aria-label': 'Star icon' }} />
                    <PText>
                      <FormattedMessage {...messages.saveUpTo} />
                      ***
                    </PText>
                  </PremiumOnly>
                  <BasicOnly>
                    <PIcon
                      name={'check'}
                      aria={{ 'aria-label': 'Check icon' }}
                    />
                    <PText>
                      <FormattedMessage {...messages.noMonthlyFee} />
                    </PText>
                  </BasicOnly>
                </div>
              </div>
            </V2Only>
          </div>
        )}
      </NorthAmericaOnly>
    </div>
  );
});

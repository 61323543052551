/* eslint-disable @typescript-eslint/naming-convention */
export const getMessagingLocaleForLanguage = (
  language: string,
): MessagingLocale => {
  if (language === '') {
    return MessagingLocale.en_GB;
  }

  const messagingLocale = Object.values(MessagingLocale).find((locale) =>
    locale.startsWith(language.toLowerCase()),
  );

  return messagingLocale || MessagingLocale.en_GB;
};

export enum MessagingLocale {
  cs_CZ = 'cs_CZ',
  da_DK = 'da_DK',
  de_DE = 'de_DE',
  en_GB = 'en_GB',
  es_ES = 'es_ES',
  et_EE = 'et_EE',
  fi_FI = 'fi_FI',
  fr_FR = 'fr_FR',
  hu_HU = 'hu_HU',
  it_IT = 'it_IT',
  lt_LT = 'lt_LT',
  lv_LV = 'lv_LV',
  nl_NL = 'nl_NL',
  no_NO = 'no_NO',
  pl_PL = 'pl_PL',
  pt_PT = 'pt_PT',
  ru_RU = 'ru_RU',
  sk_SK = 'sk_SK',
  sl_SI = 'sl_SI',
  sv_SE = 'sv_SE',
}

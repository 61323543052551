import { CountryConfig, MarketplaceConfig } from '../types';
import { isPcsCountry, PcsCountry } from './pcsCountry';
import { PcsLocale } from './pcsLocales';
import { MessagingLocale } from './messagingLocales';

export const getCountryConfig = (
  country: string,
): CountryConfig | undefined => {
  const uppercasedMarketplace = country.toUpperCase();

  if (false === isPcsCountry(uppercasedMarketplace)) {
    return;
  }

  return marketplaceConfig.find((cc) => cc.country === uppercasedMarketplace);
};

export const isVatValidationRequiredForCountry = (country: string): boolean => {
  return getCountryConfig(country)?.vatValidationRequired ?? false;
};

export const isPcsCountryMarketplaceEnabled = (country: string): boolean => {
  return getCountryConfig(country)?.enabled ?? false;
};

export const getCountryForMessagingLocale = (
  messagingLocale: MessagingLocale,
): PcsCountry => {
  return (
    marketplaceConfig.find((cc) =>
      cc.messagingLocales.includes(messagingLocale),
    )?.country ?? PcsCountry.GB
  );
};

export const getPcsLocalesIfCountryEnabled = (
  country: string,
): PcsLocale[] | null => {
  if (false === isPcsCountryMarketplaceEnabled(country)) {
    return null;
  }

  return getCountryConfig(country)?.pcsLocales ?? null;
};

const marketplaceConfig: MarketplaceConfig = [
  {
    country: PcsCountry.DE,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.de_DE],
    messagingLocales: [MessagingLocale.de_DE],
  },
  {
    country: PcsCountry.FR,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.fr_FR],
    messagingLocales: [MessagingLocale.fr_FR],
  },
  {
    country: PcsCountry.GB,
    enabled: true,
    vatValidationRequired: false,
    pcsLocales: [PcsLocale.en_GB],
    messagingLocales: [MessagingLocale.en_GB],
  },
  {
    country: PcsCountry.IT,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.it_IT],
    messagingLocales: [MessagingLocale.it_IT],
  },
  {
    country: PcsCountry.AT,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.de_AT],
    messagingLocales: [MessagingLocale.de_DE],
  },
  {
    country: PcsCountry.BE,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.nl_BE, PcsLocale.fr_BE],
    messagingLocales: [MessagingLocale.fr_FR, MessagingLocale.nl_NL],
  },
  {
    country: PcsCountry.CH,
    enabled: true,
    vatValidationRequired: false,
    pcsLocales: [PcsLocale.de_CH, PcsLocale.fr_CH, PcsLocale.it_CH],
    messagingLocales: [
      MessagingLocale.de_DE,
      MessagingLocale.en_GB,
      MessagingLocale.fr_FR,
      MessagingLocale.it_IT,
    ],
  },
  {
    country: PcsCountry.CZ,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.cs_CZ],
    messagingLocales: [MessagingLocale.cs_CZ],
  },
  {
    country: PcsCountry.DK,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.da_DK],
    messagingLocales: [MessagingLocale.da_DK],
  },
  {
    country: PcsCountry.EE,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.et_EE, PcsLocale.en_EE, PcsLocale.ru_RU],
    messagingLocales: [
      MessagingLocale.et_EE,
      MessagingLocale.en_GB,
      MessagingLocale.ru_RU,
    ],
  },
  {
    country: PcsCountry.ES,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.es_ES],
    messagingLocales: [MessagingLocale.es_ES],
  },
  {
    country: PcsCountry.FI,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.fi_FI],
    messagingLocales: [MessagingLocale.fi_FI],
  },
  {
    country: PcsCountry.HR,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.hr_HR],
    messagingLocales: [],
  },
  {
    country: PcsCountry.HU,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.hu_HU],
    messagingLocales: [MessagingLocale.hu_HU],
  },
  {
    country: PcsCountry.IE,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.en_IE],
    messagingLocales: [MessagingLocale.en_GB],
  },
  {
    country: PcsCountry.LI,
    enabled: false,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.de_LI, PcsLocale.de_DE],
    messagingLocales: [MessagingLocale.de_DE],
  },
  {
    country: PcsCountry.LT,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.lt_LT],
    messagingLocales: [MessagingLocale.lt_LT],
  },
  {
    country: PcsCountry.LU,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.fr_LU, PcsLocale.de_LU],
    messagingLocales: [MessagingLocale.de_DE, MessagingLocale.fr_FR],
  },
  {
    country: PcsCountry.LV,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.lv_LV, PcsLocale.en_LV, PcsLocale.ru_RU],
    messagingLocales: [
      MessagingLocale.lv_LV,
      MessagingLocale.en_GB,
      MessagingLocale.ru_RU,
    ],
  },
  {
    country: PcsCountry.NL,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.nl_NL],
    messagingLocales: [MessagingLocale.nl_NL],
  },
  {
    country: PcsCountry.NO,
    enabled: true,
    vatValidationRequired: false,
    pcsLocales: [PcsLocale.no_NO],
    messagingLocales: [MessagingLocale.no_NO],
  },
  {
    country: PcsCountry.PL,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.pl_PL],
    messagingLocales: [MessagingLocale.pl_PL],
  },
  {
    country: PcsCountry.PT,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.pt_PT],
    messagingLocales: [MessagingLocale.pt_PT],
  },
  {
    country: PcsCountry.SE,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.sv_SE],
    messagingLocales: [MessagingLocale.sv_SE],
  },
  {
    country: PcsCountry.SI,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.sl_SI],
    messagingLocales: [MessagingLocale.sl_SI],
  },
  {
    country: PcsCountry.SK,
    enabled: true,
    vatValidationRequired: true,
    pcsLocales: [PcsLocale.sk_SK],
    messagingLocales: [MessagingLocale.sk_SK],
  },
  {
    country: PcsCountry.US,
    enabled: true,
    vatValidationRequired: false,
    pcsLocales: [PcsLocale.en_US],
    messagingLocales: [MessagingLocale.en_GB],
  },
  {
    country: PcsCountry.CA,
    enabled: true,
    vatValidationRequired: false,
    pcsLocales: [PcsLocale.en_CA, PcsLocale.fr_CA],
    messagingLocales: [MessagingLocale.en_GB],
  },
];

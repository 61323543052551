import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import { BackendClient } from '../../../backend-client';

type AvailableFeatureFlags = {
  chargePoint?: { enabled: boolean };
};

export class FeatureFlagsContext {
  public featureFlags: AvailableFeatureFlags = {};

  constructor(props: { dummy: boolean }) {
    makeAutoObservable(this);

    const resolvedHeaders =
      typeof BackendClient.OpenAPI.HEADERS === 'object'
        ? BackendClient.OpenAPI.HEADERS
        : null;
    const authExists = resolvedHeaders && resolvedHeaders['Authorization'];

    if (props.dummy || !authExists) {
      return;
    }

    this.fetchData();
  }

  private fetchData = async () => {
    try {
      const featureFlagsResponse =
        await BackendClient.featureFlagsControllerGetFeatureFlags();
      this.featureFlags = featureFlagsResponse as AvailableFeatureFlags;
    } catch (e: unknown) {
      console.error(e);
      this.featureFlags = {};
    }
  };
}

export const featureFlagsDummyContext = new FeatureFlagsContext({
  dummy: true,
});

export const featureFlagsContext = createContext<FeatureFlagsContext>(
  featureFlagsDummyContext,
);

import React, { ReactNode, useEffect, useState } from 'react';
import {
  featureFlagsContext,
  featureFlagsDummyContext,
  FeatureFlagsContext,
} from './FeatureFlagsContext';
import { observer } from 'mobx-react-lite';

interface SubscriptionContextProviderProps {
  children?: ReactNode;
}

/**
 * Manages and rebuilds the SubscriptionContext
 */
export const FeatureFlagsContextProvider: React.FunctionComponent<SubscriptionContextProviderProps> =
  observer((props) => {
    const [context, setContext] = useState(featureFlagsDummyContext);

    useEffect(() => {
      setContext(
        new FeatureFlagsContext({
          dummy: false,
        }),
      );
    }, []);

    return (
      <featureFlagsContext.Provider value={context}>
        {props.children}
      </featureFlagsContext.Provider>
    );
  });
